@import url("comments.less");

.inner-section-border {
  border-bottom: 2px solid fade(@borderPrimary, 75%);
}

.table-no-borders td, .table-no-borders th {
  border: none !important;
}

.in-active-banner {
  display: table;
  width: 100%;
  z-index: 1000;
  top: 0;
  color: #fff;
  text-align: center;
  padding: 5px;
  margin-bottom: 5px;
  background: linear-gradient(to left, #f85032 , #e73827);
}

.body-container-module {

  &.module-finalized {
    .finalized-wrapper, .save-finalize-note {
      display: none;
    }

    .module-finalize-note {
      display: block;
    }
  }

  .module-finalize-note {
    display: none;
  }
}

.complete-module {

  &.in-active-project {
    .progress-container {
      display: none;
    }

    .main-nav {
      padding-top: 0;
      border-top: 0;
    }

    .body-container {
      margin-top: 60px;
    }
  }

  .to-top-btn {
    position: fixed;
    bottom: 20px;
    left: 50px;
    width: 32px;
    height: 80px;
    text-align: center;

    &:active, &:focus, &:hover {
      outline: none !important;
    }

    img {
      max-height: 100%;
      max-width: 100%;
    }

    a {
      display: block;
      margin-bottom: 5px;
      &:last-child {
        margin: 0;
      }
    }

    .save-all {
      font-size: 35px;
      &.disabled {
        color: #eee;
      }
    }

  }

  textarea {
    min-height: 75px;
  }

  .stacked-buttons {
    margin: 0;
    li {
      padding-bottom: 5px;
      &:last-child {
        padding: 0;
      }
    }
  }

  .module-header {
    margin: 30px 0 20px;
    padding-bottom: 9px;
    border-bottom: 1px solid #eee;

    &.sbModuleHeader {
      position: fixed;
      z-index: 1999;
      width: 100%;
      background-color:white;
      margin:0px 0px 0px -15px;
    }

    .title h3 {
      margin-top: 0px;
      font-size: 18px;
      &.project-name {
        margin-top: 10px;
        font-size: 18px;
      }
    }

    .dropdown-menu {
      .rs-dropdown;
      > li > a {
        font-weight: 400;
        font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }
    }

    .progress {
      margin-bottom: 10px;
    }

    .preview {
      .loader-container {
        padding-left: 5px;
      }

      ul {
        margin: 0;
        li {
          padding-left: 3px;
          padding-right: 3px;
        }
      }

      .preview-links {
        width: 100%;
        th, td {
          padding: 2px;
        }

        .list-finalized {
          input[type='checkbox'] {
            margin: 0;
          }

          li {
            vertical-align: top;
          }
        }
      }

      .edit-link {
        font-size: 12px;
      }
    }
  }
  
  .helpful-resources {
    display: inline-block;
  }
  
  .panel-body {
    padding-top: 10px;
  }

  .caption {
    font-size: 11px;
    color: #555;
    margin-top: 5px;
  }

  .image-preview {
    max-width: 100%;
    max-height: 100%;
  }

  .title-no-margin {
    margin-top: 0;
  }

  .upload-form-links {
    margin: 0;
    padding-top: 8px;
    font-size: 12px;
  }

  .widget-container {

    .loading-bar {
      margin-bottom: 5px;
      text-align: right;
    }

    .top-info-bar {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
      display: table;
      width: 100%;
      position: relative;
    }

    .resolve {
      color: #ddd;
    }

    .previous-answers, .widget-buttons {
      display: table-cell;
      vertical-align: middle;
    }

    .dropdown-menu {
      .rs-dropdown;
      &> li {
        padding-bottom: 3px;
        border-bottom: 1px solid fade(@borderPrimary, 50%);
      }
    }

    .dropdown-previous-answers {
      max-height: 400px;
      width: 350px;
      overflow-x: hidden;
      overflow-y: auto;

      .action-bar {
        margin-bottom: 2px;
      }

      .answer-row {
        margin-bottom: 5px;
      }

      .dropdown-wrapper {
        padding: 3px 10px;
      }

      a:hover {
        background-color: transparent;
      }

      .dropdown-item {
        display: inline-block;
        padding: 0;
      }
    }

    .widget-buttons {
      text-align: right;

      .finalized-wrapper {
        label, input[type='checkbox'] {
          margin: 0;
        }
      }

      ul {
        margin: 0;
      }
    }

    .save-finalize-note, .module-finalize-note {
      margin-top: 5px;
      font-size: 11px;
      color: #888;
    }
    
    .horizontal-form-container {
      padding-bottom: 15px;
      margin-bottom: 15px;
      .inner-section-border;
    
      &:last-of-type, &.last {
        border: 0;
      }
    
      .form-group:last-child {
        margin-bottom: 0;
      }
    
      .text-display {
        padding-top: 7px;
      }
    }

  }

  .search-results {
    max-height: 400px;
    overflow-y: auto;
  }

  .text-container {

    textarea.form-control {
      height: 150px;
    }

    .token-list {
      font-size: 12px;
      margin: 0;

      li {
        padding-bottom: 3px;
        &:last-child {
          padding: 0;
        }
      }
    }
  }

  .figure-upload-container {

    table thead th {
      text-align: center;
    }

    .uploaded-figure {
      width: 40%;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .image-text-container {

    .angular-medium-editor {
      resize: vertical;
    }

    .element-container {
      margin-top: 10px;
      background-color: #F5F5F5;
      border: 1px solid fade(@borderPrimary, 70%);
      border-radius: 2px;
      padding: 8px;
      
      .image-preview {
        margin-bottom: 10px;
      }      
    }

    textarea {
      min-height: 250px;
    }

    .buttons-bottom {
      margin-top: 10px;
    }
    
    .image-txt-btns {
      position: relative;

      .insert-table-container {
        position: absolute;
        right: -165px;
        top: 35px;
        z-index: 1;
        background-color: #fff;
        border: 1px solid #eee;
        width: 250px;
        padding: 10px;

        table {
          width: 100%;
          td {
            padding-bottom: 5px;
          }
        }
      }
    }

    .button-bar {
      margin-bottom: 8px;
      display: table;
      width: 100%;

      .buttons-left {
        display: table-cell;
      }

      .buttons-right {
        display: table-cell;
        text-align: right;
      }
    }

    .list-caption-container {
      margin: 10px 0 0;

      .input-sm {
        width: 400px;
      }

      li {
        vertical-align: bottom;
      }
    }

    .text-header {
      margin-bottom: 5px;
    }

    .table-widget-row-slider {

      .table-inner {
        width: 100%;
        position: relative;
        overflow: auto;
        margin-top: 30px;
      }

      table {
        width: 100%;
      }

      table th {
        min-width: 100px;
      }

      table .actions {
        min-width: 50px;
        text-align: center;
      }

      table td, table th {
        white-space: normal;
      }

      table.medium-rs-table td, table.medium-rs-table th {
        white-space: normal;
      }

      .column-header {
        margin-bottom: 3px;
      }

      .input-textarea-small {
        min-height: 75px;
      }

      .edit-column-container {
        margin-bottom: 0;
        .form-control {
          display: inline-block;
          width: 300px;
        }
      }
    }

    .table-widget-row {
      margin-top: 20px;
      
      .remove-btn {
        margin-top: 5px;
      }
      
      .column-error {
        font-size: 12px;
      }

      .table-widget-columns {
        td, th {
          font-size: 12px;
        }
      }
      
      .table-row-container {
      
        .form-horizontal {
          border-bottom: 1px solid fade(@borderPrimary, 50%);
          margin-bottom: 5px;
          padding-bottom: 5px;
          
          &:last-child, &.last {
            border: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }      
      }      
    }

  }

  .model-table-container {
    .col-desc {
      width: 40%;
    }
  }

  .document-upload-container {

    .image-preview {
      text-align: center;
      img {
        max-width: 100%;
        max-height: 100%;
        padding: 3px;
        border: 1px solid #eee;
      }
    }

    .current-file {
      margin: 0;
      label {
        margin: 0;
      }
      li {
        padding-bottom: 8px;
      }
    }
  }

  .standards-table-container {

    .horizontal-form-container {
      .inner-section-border;
    }

    .search-bar {
      display: table;
      width: 100%;
      margin-bottom: 10px;

      .widget {
        display: table-cell;
        vertical-align: middle;

        &:last-child {
          padding-left: 8px;
        }
      }
    }

    .form-3654 {
      margin-top: 15px;
    }
  }

  .company-info-container {

    .form-table.table-condensed {
      table-layout: fixed;

      td, th {
        padding: 3px;
        font-size: 12px;
      }

      .form-control {
        padding: 5px;
        height: 30px;
        font-size: 12px;
        box-shadow: none;
        border: 1px solid #eee;

        &:focus {
          border-color: #66afe9;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 2px rgba(102, 175, 233, 0.6);
        }
      }

      .city-state .form-control {
        display: inline;
        &.input-state {
          width: 25%;
        }
        &.input-zip {
          width: 25%;
        }
        &.input-country {
          width: 45%;
        }
      }
    }

    .company-info {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;

      &:last-child {
        margin: 0;
        padding: 0;
      }

      table {
        margin: 0;
      }
    }

    .list-phone-parts {
      margin: 0;
      li {
        width: 33%;
      }

      .form-control::-moz-placeholder {
        color: #333;
        opacity: .5;
      }

      .form-control::-webkit-input-placeholder {
        color: #333;
        opacity: .5;
      }
    }

  }

  .product-codes-container {
    .alert {
      margin: 0;
    }

    .search-table-container {
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid #eee;
    }
  }

  .sse-products-container {
    .filter-note {
      font-size: 13px;
      text-align: right;
    }

    .device-name {
      font-size: 13px;
    }
  }

  .study-investigators-widget {

    .yes-no-list label {
      font-weight: normal;
      cursor: hand;
      cursor: pointer;
    }

    .yes-no-list input[type="radio"] {
      margin-top: 0;
    }

  }

  .panel-finalized {
    .custom-opacity, .current-label { // newly added css
      opacity: .65
    }

    a, button {
     // opacity: .65;
      &.ignore-finalized {
        visibility: visible !important;
      }
    }

    .medium-editor-container a, .medium-editor-container button {
      visibility: visible;
    }
  }

}

.component-test-results-table-container {
  .form-horizontal {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid #eee;

    &:last-child {
      border: 0;
    }

    .form-group:last-child {
      margin-bottom: 0;
    }
  }
}

.component-sterilization-table-container {
  .form-horizontal {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid #eee;

    &:last-child {
      border: 0;
    }

    .form-group:last-child {
      margin-bottom: 0;
    }
  }
}

.previous-fda-communication-table-container {

  .feedback-container {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid #eee;

    &:last-child {
      border: 0;
    }

    .form-group:last-child {
      margin-bottom: 0;
    }

    .feedback-image-text {
      margin-top: 15px;
    }
  }

}

.template-pdf-upload {
  .sign-how-selector {
    margin-top: 7px;
    margin-bottom: 3px;
    .active {
      font-weight: bold;
      a {
        color: #333;
      }
    }
  }

  .recorded-note {
    font-size: 11px;
    color: #aaa;
  }

  .signature-preview {
    margin-top: 20px;

    .image-preview {
      padding: 2px;
      border: 1px solid #eee;
    }
  }
}

.references-container {

  .reference-title {
    width: 70%;
    word-break: normal;
  }

  .note {
    font-size: 11px;
  }
}

.popover-errors {
  display: block;
  position: fixed;
  bottom: 20px;
  top: unset;
  left: 10px;
  height: auto;
  max-width: unset;
  width: 350px;
  padding: 0;

  .close {
    color: #fff;
    opacity: 1;
    margin-top: -4px;
  }

  .popover-title {
    background: #a94442;
    color: #fafafa;
    border-bottom: 1px solid #ebccd1;
  }

  .popover-content {
    background-color: #f2dede;
    color: #a94442;

    ul {
      padding: 0;
      margin: 0;
      li {
        padding-bottom: 3px;
      }
    }
  }
}

.component-table-container {
  .element-container .form-group {
    margin-left: 0;
  }
}

.rta-checklist-container {
  .rta-label {
    font-weight: bold;
  }

  .help {
    margin-top: 5px;
    color: #666;
    font-size: 13px;
  }

  .fa-autopopulate, .check-td .fa {
    font-size: 1.25em;
  }

  .check-td {
    text-align: center;
    vertical-align: middle;
  }

  .rta-comments-rendered {
    margin: 0;
  }

  .rta-comments {
    margin: 0;
    display: table;
    width: 100%;
    li {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .rta-checklist-table {
    .rta-sub-question {
      padding-left: 30px;
      &.deep {
        padding-left: 50px;
      }
    }

    .block-or {
      display: block;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .selected a {
      font-weight: bold;
      color: #333;
    }
  }

}

.pubmed-searchresults-container {
  .hidden-citation {
    opacity: .3;
  }

  .list-no-margin {
    margin: 0;
  }

  .pubmed-searchresults-scroll-container {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 10px;
  }

  .citation {
    margin-bottom: 5px;
  }

  .list-section-banner {
    display: table;
    width: 100%;
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #ccc;

    h4 {
      margin-top: 0;
      padding-top: 0;
    }

    .section-right {
      font-weight: bold;
    }

    li {
      display: table-cell;
      width: 50%;
    }
  }

  .abstract {
    margin-top: 5px;
    padding-top: 5px;
  }
}

.form-group.disabled, .form-horizontal.disabled, .widget-container.disabled .horizontal-form-container {

  input[type='text'], input[type='radio'], select, button, textarea {
    cursor: not-allowed;
  }

  button {
    opacity: .65;
    box-shadow: none;
  }

  input[type='text'], textarea, select {
    background-color: #eee;
  }

}

.widget-presub-search {

  &.regulatory-intel {
    .search-results {
      max-height: 80vh;
    }
  }

  .list-dates .input-wrapper {
    width: 100px;
  }

  .search-results {
    max-height: 400px;
    overflow-y: auto;

    .no-margin {
      margin-bottom: 3px;
    }
  }
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
  background-color: #eee !important;
}

a.disabled {
  opacity: .3;
}

a.selected {
  font-weight: bold;
  color: #333;
}

.sse-discussion-table-container {

  .table-sse-pivoted {
    table-layout: fixed;
  }

}

.modal-compare-answers-modal {
  width: 90%;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pb-1 {
  padding-bottom: .75em;
}

.table-fixed {
  table-layout: fixed;
}

.mt-2 {
  margin-top: 20px;
}

.mt-1 {
  margin-top: 10px;
}
.mr-2 {
  margin-right: 20px !important;
}

.form-control-div {
  padding-top: 6px;
  padding-bottom: 6px;
}

.mdr-upload-widget-component {
  .control-file-label {
    padding-top: 0;
  }
}

.cer-literature-component {
  .alert-error {
    max-height: 200px;
    overflow: auto;
  }

  .pubmed-searchresults-scroll-container {
    max-height: 100vh !important;
  }
}

.float-right {
  float: right;
}

.ref-super {
  top: -.5em;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.section-help {
  color: #aaa;
}

.mx-label-item {
  margin: 5px;

  &.selected a {
    border: 5px solid #00518a !important;
  }

  a {
    display: inline-block;
    border: 5px solid transparent;
    &:hover img {
      border: 1px solid #aaa;
      box-shadow: 0px 0px 0px 1px #000;
    }
  }

  img {
    width: 100px;
    border: 1px solid #eee;
    padding: 3px;
  }
}

.mx-clear-btn {
  margin-bottom: 2rem;
}

.save-all-error {
  position: fixed;
  width: 100vw;
  bottom: 0px;
  height: fit-content;
  z-index: 2;
}

image-text-figure .ck-editor__editable_inline {
  min-height: 300px;
}

.ck-content .mention, .comment-body .mention {
  background: none;
  color: #00518a;
  font-weight: bold;
}

.manage-files-container {
  .file-list {
    margin-top: 10px;
    li {
      padding-bottom: 10px;
    }
  }
}

.modal-body-footnotes {
  .insert-footnote {
    font-size: 13px;
  }

  .footnote-text {
    margin-top: 5px;
  }
}

.pointer-events-none {
  cursor: not-allowed;
  &:active {
    pointer-events: none;
  }
  label, button, a, div {
    cursor: not-allowed;
    pointer-events: none;
  }
  .compare-toggle {
    cursor: pointer !important;
    pointer-events: all !important;
  }
}

.row{
  &.sbNote{
    margin-top: 15%;
  }
}

:root {
  --is-sx-project: 999;
}

.modal-open .modal {
  z-index: 2001 !important;
}

.modal-backdrop.in {
  z-index: var(--is-sx-project) !important;
}

.modal-smart-docs-select-container {
  margin: 2rem;
}

.smart-docs-container {
  max-height: 60vh;
  overflow: auto;
}

@media (max-width: 1023px) {
  .col-help {
    margin-top: inherit;
    div {
      padding-top: 20px;
    }
  }
}
@media  (min-width: 1024px) and (max-width: 1294px)   {
  .col-help {
    margin-top: 40px;
  }
}

.modal.custom {
  outline:none;
}

.mb-16 {
  margin-bottom: 16px;
}
.pl-26 {
  padding-left: 26px;
}
.sd-modules-wrapper {
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 60px;
}
.module-wrapper {
  border: 1px solid #d3d6d9;
  font-weight: 500;
  line-height: 24px;
  padding: 24px 32px 8px;
  margin-bottom: 22px;
  img {
    height: 32px;
    margin-right: 12px;
    vertical-align: text-bottom;
    width: 32px;
  }
  .no-data {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.sd-modal-header {
  font-size : 20px;
  color : #0b3c61;
 }

.d-flex {
  display: flex;
}

.col-gap-15 {
  column-gap: 15px;
}

.align-items-center {
  align-items: center;
}

.word-break {
  word-break: break-word;
}

.cursor-pointer {
  cursor: pointer;
}

.events-none {
  cursor: not-allowed;
  pointer-events: none;
}

.active-crump {
  color: #0b3c61;
}

.modal-smart-docs .modal-body .crump-chevron {
  height: 24px;
  width: 24px;
}

.emergo-white-button {
  background-color: #fff;
  border: 1px solid #0b3c61;
  box-sizing: border-box;
  border-radius: 20px;
  color: #0b3c61;
  font-weight: 500;
  font-size: 18px;
  font-family: Roboto;
  line-height: 21px;
  padding: 6px 15px;
}

.emergo-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

    thead tr {
      border-bottom: 1px dashed #eee;
    }

    tbody {
      tr:not(:last-child) {
        border-bottom: 1px solid #bdbdbd;
      }
    }
}

/*element {
  width: 65px;
}*/

.emergo-table .table > thead:first-child > tr:first-child > th {
  font-weight: 400;
}

.emergo-table .table > caption + thead > tr:first-child > td, .table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > td, .table > thead:first-child > tr:first-child > th {
  border-top: 0;
}

.emergo-table > tbody > tr > td, .emergo-table thead tr th {
  padding: 16px;
}
/*
.table > thead > tr > th {
  padding: 16px;
}*/

.emergo-table thead tr th {
  border: 0;
  border-top-width: 0px;
  border-top-style: none;
  border-top-color: currentcolor;
  font-size: 14px;
  color: #0b3c61;
  font-weight: 500 !important;
  vertical-align: middle;
  border-bottom: 1px dashed  #eee;
}

/*.emergo-table tbody tr {
  border-bottom: 0.7px solid #bdbdbd;
}*/

.header_box {
  border-bottom: 0.5px solid #bdbdbd;
  border-top: 0.5px solid #bdbdbd;
  padding-bottom: 17px;
  padding-top: 17px;
  margin-left: 8px;
}

.header_box .product-count {
  font-size: 17px;
  line-height: 22px;
  font-weight: normal;
}

/*
.emergo-table thead tr {
  border-bottom: 1px dashed #eee;
}
*/

.col-gap-16 {
  -moz-column-gap: 16px;
  column-gap: 16px;
}

.align-items-start {
  align-items: start;
}

.sub-header {
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
}

.sort.desc:before {
  content: url("../images/sortDesc.svg");
}

.sort.asc:before {
  content: url("../images/sortDesc.svg");
  display: inline-block;
  transform: rotate(180deg);
  position: relative;
  top: 2px;
}

.sortPosition:before {
  position: relative;
  top: -1px;
}

.no-sort {
  /* Define styles for no sorting indicator */
  content: url("../images/sortDesc.svg");
}

.modal-open .left-modal {
  left: -20%;
 /* transform: translateX(0); *//* Reset the horizontal translation */
}