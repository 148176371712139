.documents {

  .body-container {
    margin-top: 60px;
  }

  .document-progress-container {
    position: fixed;
    top: 46px;
    width: 100%;
    z-index: 1001;

    .progress-bar {
      transition: none !important;
    }

    .progress {
      height: 4px;
      margin: 0;
    }
  }

  .page-header {
    margin-top: 0;
    margin-bottom: 15px;

    h1 {
      margin-top: 0;
    }
  }

  .documents-menu {
    text-align: right;
    margin-top: 10px;
  }

  .document-column {
    min-height: 100vh;
    position: relative;
  }

  .switch-view-buttons {
    margin-bottom: 5px;
    text-align: right;
  }

  .form-control.input-extra-long {
    height: 500px;
  }

  .upload-target {
    display: block;
    height: 100%;
    width: 100%;
    background-color: #fafafa;
    position: absolute;
    border: 1px solid #ececec;
    cursor: hand;
    cursor: pointer;

    button, .ng-loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .pdf-js {
    height: 100%;
    width: 100%;
    position: absolute;
    &.no-pdf {
      z-index: -1;
    }
  }

  .alert-validation {
    margin-bottom: 5px;
    border-radius: 3px;
    padding: 8px;
  }

  .document-upload-bar {
    text-align: right;
  }

  .modal-preview-document-modal {
    width: 90%;
  }

  .modal-preview-document {

    .preview-container {
      text-align: center;
    }

    .thumbnail {
      max-width: 100%;
      margin-bottom: 10px;
      width: 100%;
      display: inline-block;
    }

    .preview-buttons {
      text-align: right;
      margin-bottom: 5px;
    }

    .preview-help {
      margin-top: 5px;
      text-align: right;
      font-size: 12px;
    }

    .preview-pdfjs-container {
      height: 100vh;

      .preview-pdf-js {
        height: 100vh;
        width: 100%;
        &.no-pdf {
          z-index: -1;
        }
      }
    }

  }

  .input-error {
    border-color: #a94442;
  }

  .list-date-range {
    margin: 0;
    li {
      vertical-align: middle;
    }
    .form-control {
      width: 100px;
    }
  }

  #timeline {
    .tl-media-content {
      .tl-media-image {
        padding: 2px;
        border: 1px solid #eee;
      }
    }

    .tl-headline {
      line-height: 1.4;
    }

    .timeline-slide-actions {
      a {
        margin-left: 5px;
        color: #0070c0;
        &:first-child {
          margin-left: 0;
        }
        i {
          font-style: normal;
        }
      }
    }

    .tl-timemarker-content-container .timeline-slide-actions {
      display: none;
    }

    .tl-slide-titleslide .tl-media-item {
      visibility: hidden;
    }
  }

  .select2-container-multi .select2-choices .select2-search-field input {
    font-family: 'Source Sans Pro', 'Open Sans', sans-serif !important;
  }

  .document-info-table {
    margin: 0;
    th {
      padding-left: 0;
    }
    tr td, tr th {
      border: none;
    }
  }

  .documents-pager {
    text-align: center;

    .pagination {
      margin: 0;
      &.disabled {
        opacity: .4;
      }
    }
  }

  .search-header {
    a {
      vertical-align: top;
    }

    .fa {
      font-size: 11px;
    }
  }

  .document-delete-button {
    text-align: left;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

  .analytics-container {

    .analytics-row {
      margin-top: 20px;
      &.extra-padding {
        margin-top: 60px;
      }
    }

    .progress {
      margin-bottom: 0;
    }

    .analytics-hero {
      text-align: center;

      &.analytics-by-category {
        text-align: left;
      }

      .metric-value {
        font-size: 48px;
        color: #337ab7;
        font-weight: bold;
        margin-bottom: 5px;
        line-height: 1;
      }

      .metric-name {
        font-size: 24px;
        color: #337ab7;
        font-weight: bold;
        line-height: 1;
      }
    }

    .autocomplete-find-none {
      margin-top: 5px;
      label, input {
        margin: 0;
      }
    }
    
  }

  .preview-alert {
    font-size: 12px;
    padding: 8px;
    text-align: center;
  }

  .alert-qc {
    margin-bottom: 0;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
  }

  .last-saved-by {
    color: #999;
    margin-top: 3px;
    font-size: 13px;
  }
}