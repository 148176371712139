@import url("navigation.less");
@import url("module.less");
@import url("htmldiff.less");

@green: #4c9e45;
@teal: rgba(16, 117, 98, 1);
@blue: #0b3c61;
@lightblue: #00518A;
@midnight: rgba(42, 51, 61, 1);
@black: rgba(0, 0, 0, 0.7);

@borderPrimary: @midnight;
@borderSecondary: @blue;

html, body {
  font-family: Roboto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Roboto;
}

a {
  color: @blue;
}

.header-logo {
  max-height: 30px;
}

.exit-impersonation {
  color: red !important;
  font-weight: bold !important;
}

.page-header {
  border-color: fade(@borderPrimary, 50%);
}

.alert.alert-help {
  padding: 8px;
  margin-bottom: 0;
  margin-top: 5px;
  border-radius: 2px;
  font-size: 13px;
}

.form-control {
  border-radius: 2px;
  &:focus {
    border-color: @borderSecondary;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 2px fade(@borderSecondary, 60%);
  }
}

.ng-loader {
  max-width: 32px;
  max-height: 32px;
}

.login {
  .forgotpass-link {
    margin-top: 5px;
  }
}

.table-centered-headers {
  thead th {
    text-align: center;
  }
}

.login .version-number {
  margin-top: 20px;
  color: #ccc;
  font-size: 12px;
}

.sep {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
}

.rs-dropdown {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0;
  margin: 0;
  padding: 0;

  &> li > a {
    font-size: 1em;
    padding: 3px 10px;
    color: @blue;

    &.text-danger {
      color: #a94442;
    }
  }
}

.setup-project {

  .navbar .navbar-nav {
    display: none;
  }

  .onboarding-steps {
    margin: 0 0 15px;
    color: #888;

    .active {
      background-color: #337ab7;
      color: #fff;
      padding: 10px 15px;
      border-radius: 4px;
      font-weight: bold;
    }
  }

  .module-configuration {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;

    &:last-child {
      border: 0;
    }
  }

  .alert-small {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
  }
}

.my-account {
  .update-password-link {
    display: block;
  }
}

.view-project {

  &.in-active-project {
    .main-nav {
      padding-top: 0;
      border-top: 0;
    }

    .body-container {
      margin-top: 60px;
    }
  }

  #searchForm {
    .alert {
      margin-bottom: 0;
    }

    .search-result {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;

      &:last-child {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 0;
      }

      .search-header {
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 5px;
      }

      em {
        font-weight: bold;
      }

      .search-user {
        font-size: 12px;
        color: #666;
        margin-top: 5px;
      }
    }
  }

  .export-table {
    .export-checkbox {
      margin: 0;
    }

    .pagination {
      margin: 0;
      > li > a, > li > span {
        padding: 3px 10px;
      }
    }

    .loading {
      max-height: 32px;
    }
  }

  .page-loader {
    float: right;
    max-width: 32px;
  }
}

.module-activity {
  .activity-body {
    padding-left: 15px;
    padding-right: 15px;
  }

  .date-header {
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 5px;
    padding-bottom: 3px;
    border-bottom: 1px solid #eee;
    font-family: Roboto;
  }

  .activity-list li {
    padding-bottom: 10px;
  }

  .activity-time {
    color: #aaa;
  }
}

.pma-container {
  table {
    margin-bottom: 0;
  }

  .pma-margin-left {
    margin-left: 15px;
  }

  .sep {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #000;
  }

  .top-box {
    min-height: 310px;
  }

  .city-state .form-control {
    display: inline;

    &.input-state {
      width: 25%;
    }
    &.input-zip {
      width: 25%;
    }
    &.input-country {
      width: 45%;
    }
  }

  .pma-banner {
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;

    .list-header-bold {
      font-weight: bold;
    }

    .text {
      float: left;
    }

    .text-section {
      width: 5%;
    }

    .text-type {
      width: 95%;
      text-align: center;
    }
  }
}

.list-dashboard-buttons {
  li {
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.form-control.medium-html-editor {
  min-height: 400px;
}

.medium-editor-container {
  position: relative;

  .editor-placeholder {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #333;
  }

  .medium-rs-table {
    width: 100%;

    td, th {
      border: 1px solid #ccc;
    }
  }


  .medium-buttons {
    margin-top: 5px;
    width: 100%;
  }

  .insert-tokens {
    font-size: 12px;
    max-width: 500px;
    max-height: 300px;
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .insert-links-nested {
    margin-left: 20px;
    padding-bottom: 10px;
  }

  .insert-special {
    font-size: 2rem;
  }
}

.medium-editor-toolbar-select {
  background: #000;
  border: 0;
  padding: 0;
  margin-left: 5px;
  border-radius: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  font-size: 14px;
  vertical-align: text-top;
}

.medium-editor-toolbar-form {
  border-radius: 10px !important;
}

.medium-link-modal {
  padding: 10px;
  width: 100%;
  position: relative;

  .medium-editor-toolbar-close {
    margin: 0 !important;
    top: 5px !important;
  }

  .medium-editor-toolbar-save, .medium-editor-toolbar-close {
    font-size: 20px;
  }

  .medium-editor-toolbar-close {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .medium-link-table-container {
    position: relative;
    max-height: 400px;
    overflow-y: auto;
    padding: 5px;
  }

  .button-bar {
    margin-bottom: 8px;
  }

  .nav-tabs {
    border-bottom: 0;
    padding-bottom: 5px;
    margin-bottom: 5px;

    .active {

      a {
        background-color: transparent;
        font-weight: bold;
        color: #fff !important;

        &:hover, &:active, &:focus {
          background-color: transparent !important;
        }
      }

    }

    li a {
      border: 0 !important;
      &:hover, &:active, &:focus {
        background-color: transparent !important;
      }
    }

  }

  .tab-link {
    font-size: 13px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 8px;
    color: #fff;

    &.first {
      margin-left: 0;
    }
  }

  .medium-link-table {
    width: 100%;
    font-size: 14px;

    .medium-link-header {
      font-weight: bold;
      font-size: 15px;
    }

    .nested-link {
      padding-left: 20px;
      font-size: 13px;
    }

    .medium-link-modal-link {
      display: block;
      font-size: 14px;
      font-weight: normal;
      margin: 0;
      transform: unset;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.nvtooltip h3 {
  font-size: 13px !important;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif !important;
}

.modal-body .body-title {
  font-family: Roboto;
  font-size: 16px;
  margin-bottom: 10px;
}

.regulatory-intel {
  .email-banner {
    height: 80vh;
    width: 100%;
    background-color: rgba(0, 0, 0, .80);
    position: absolute;

    .progress {
      height: 8px;
    }

    h3 {

      color: #fff;

      &.first {
        margin-top: 50px;
      }

      strong {
        text-shadow: 0px 3px 2px rgba(0, 0, 0, 1);
        font-size: 28px;
      }

    }

    .inner {
      padding: 15px;
      margin-top: 30px;
      border-radius: 5px;
      background-color: #fff;
    }
  }

  .regintel-result {
    padding: 10px;
    margin-bottom: 10px;

    .title {
      font-family: Roboto;
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 10px;
    }

    table {
      margin-bottom: 10px;
    }

    .sep {
      border-bottom: 1px solid #eee;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .searches-container {
    max-height: 150px;
    overflow-y: auto;
    ul {
      padding-right: 10px;
    }
  }

  .sample-searches {
    min-height: 150px;
    display: block;
  }
}

.taggable-users {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0;
  min-width: 160px;
  z-index: 1000;
  font-size: 13px;
  background-color: #fff;
  visibility: hidden;

  .list-taggable-users {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  a {
    padding: 3px 10px;
    display: block;
    &:hover {
      background-color: #f5f5f5;
    }
  }

  .target {
    border: 0;
    box-shadow: none;
    padding: 5px 5px;
    &:focus {
      border: 0 !important;
      box-shadow: none !important;
    }
  }
}

#autoTarget {
  position: absolute;
  height: 1px;
  width: 1px;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}

.module-due-date {
  font-weight: bold;
  &.text-warning {
    color: #F25B00;
  }
  &.text-danger {
    color: #A90300;
  }
}

.dashboard-comment-container {

  margin-bottom: 10px;
  padding-bottom: 10px;

  .comment-module-section {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .panel {
    margin-bottom: 15px;
    border-radius: 0;

    .panel-heading, .panel-footer {
      padding: 5px 10px;
    }

    .panel-body {
      padding: 5px;
    }
  }

  .list-nested-comments {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid #ddd;
  }

  .list-nested-comments-text, .panel-footer-text {
    display: flex;
    align-items: center;
    .comments-separator {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    input {
      margin-top: 0;
    }
  }
}

.with-highlight {
  font-weight: bold;
}

/*
 * Styles related to azure and rams
 */
.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
  color:#fff;
  background-color: @blue;
}

a {
  color: #00518a;
  text-decoration: none;
}
.text-primary {
  color: #00518a;
}
.text-info {
  color: #fff;
}
.bg-primary, .bg-info {
  background-color: #00518a;
}

.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info {
  background-color: #00518a;
  color: #fff
}

.table>tbody>tr.info>td>a,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info {
  background-color: #00518a;
  color: #fff!important
}
.backVector {
  margin-right: 7px;
  color : #0b3c61;
}

.btn-primary {
  background-color:#0B3C61;
  border: 1px solid #0B3C61 !important;
  border-radius: 20px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 21px !important;
  text-align: center;
}

.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
  background-color: #00518a;
  border-color: #2e6da4
}

.btn-primary .badge {
  color: #00518a;
  background-color: #fff
}

.btn-link {
  color: #00518a;
}

.caret_new {
  margin-left:706px;
  vertical-align: bottom;
}

.dropdown-main {
  margin: 35px 0px 0px 12px !important;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #00518a;
  outline: 0
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
  color: #fff;
  background-color: #00518a
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #00518a;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #00518a;
  border-color: #00518a
}

.label-primary {
  background-color: #00518a
}

.list-group-item.active>.badge,
.nav-pills>.active>a>.badge {
  color: #00518a;
  background-color: #fff
}

a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
  border-color: #00518a
}

.alert-info {
  color: #fff;
  background-color: #00518a;
  border-color: #bce8f1
}

.alert-info a {
  color: #fff;
  text-decoration: underline;
}
.progress-bar {
  background-color: #00518a;

}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  z-index: 2;
  color: #fff;
  background-color: #00518a;
  border-color: #00518a
}

.list-group-item-info {
  color: #fff;
  background-color: #00518a
}

a.list-group-item-info,
button.list-group-item-info {
  color: #fff
}
a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #fff;
  background-color: #c4e3f3
}

a.list-group-item-info.active,
a.list-group-item-info.active:focus,
a.list-group-item-info.active:hover,
button.list-group-item-info.active,
button.list-group-item-info.active:focus,
button.list-group-item-info.active:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff
}

.panel-primary {
  border-color: #00518a
}

.panel-primary>.panel-heading {
  color: #fff;
  background-color: #00518a;
  border-color: #00518a
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #00518a
}

.panel-primary>.panel-heading .badge {
  color: #00518a;
  background-color: #fff
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #00518a
}
.panel-info>.panel-heading {
  color: #fff;
  background-color: #00518a;
  border-color: #bce8f1
}
.panel-info>.panel-heading .badge {
  color: #00518a;
  background-color: #fff
}

.page-header-dashboard {
  border-bottom: none !important;
  h1 {
    color: @blue;
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 16px;
  }
}

.page-subheader-dashboard {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: @black;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.header-menu-dashboard-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 2rem;
  .page-header-dashboard {
    h1 {
      margin: 0;
    }
  }
  .page-menu-dashboard {
    width: 350px;
  }
}

.user-manual{
  position: absolute;
  font-size: 18px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #FFFFFF;
  /* Grays / Black */
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  height: 35px;
  width: 350px;
  cursor: pointer;
}

.user-manual-caret {
  margin-left:320px;
  vertical-align: bottom;
}

.user-manual-dropdown {
  padding-top: 8px;

  .manual-inner {
    margin: 0;
    padding: 3px 10px;
    .lang {
      padding-left: 2px;
      padding-right: 2px;
      font-size: 85%;
    }
  }

  .dropdown-option {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
  }
}

.product-table {

  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  th, td {
    padding: 0 24px;
  }

  thead {
    color: @blue;
    tr {
      height: 45px;
    }
    th {
      border-bottom: 1px dashed #eeeeee;
    }
  }

  tbody {

    color: @lightblue;

    tr {
      height: 60px;
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      cursor: pointer;
    }

    .product-table-name {
      font-size: 18px;
      line-height: 21px;
    }

    .product-table-status, .product-table-modified, .product-table-chevron {
      font-size: 14px;
      line-height: 16px;
    }

    .product-table-status {
      width: 140px;
    }

    .product-table-modified {
      width: 175px;
    }

    .product-table-chevron {
      width: 50px;
      text-align: right;
    }

  }

}

.product-divider {
  margin: 10px 0;
}

.modal-mobile-error {
  .info-icon-wrapper {
    margin-bottom: 20px;
    .info-icon {
      display: inline-block;
      background-color: #F2994A;
      height: 50px;
      width: 50px;
      border-radius: 30px;
    }

    .fa-info {
      margin-top: 10px;
    }
  }

  .btn-wrapper {
    width: 60%;
    margin: auto;
  }
}

.nav-rams-wrapper {
  margin-bottom: 15px;
  /*padding-bottom: 5px;*/
  border-bottom: .5px solid #bdbdbd;

  .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .nav>li {
    display: block;
  }

  .nav>li>a {
    padding: 5px 15px;
    border-radius: 0;
    color: #000000;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
  }

  .nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
    color: #000000;
    font-weight: bold;
    background-color: unset;
    border-bottom: 8px solid rgb(76, 158, 69);
  }

  .nav>li>a:focus, .nav>li>a:hover {
    background-color: unset;
    border-bottom: 8px solid rgb(76, 158, 69);
  }


}
.moduleName {
  position: absolute;
  font-size: 18px;
  left: 24px;
  right: 26px;
  top: 25%;
  bottom: 22.22%;
}

.otherModule{
  position: absolute;
  font-size: 18px;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #FFFFFF;
  /* Grays / Black */
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  height: 35px;
  width: 733px;
}

.fancyDropdown {
  font-size: 18px;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #FFFFFF;
  /* Grays / Black */
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  min-width: min-content;
  height: 35px;
  padding: 8px;
  display: block;
  position: relative;
  .caret {
    position: absolute;
    right: 10px;
    top: 15px;
  }
}

.project_name_header{
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 28px !important;
  line-height: 33px !important;
  color: #0B3C61;
}

h3.project-name{
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #0B3C61;
}
@media (max-width:991px){
  .progress {
    margin-top: 55px !important;
    max-width: 300px;
  }

}

.dashboard-widget-container {

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .dashboard-widget {

    width: 47%;
    height: 332px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 12px;

    display: flex;
    flex-direction: column;


    .dashboard-widget-tabs {

      height: 45px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.7);

      ul {

        list-style: none;
        padding-left: 0;
        height: 100%;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        li {

          display: flex;
          align-items:center;

          font-weight: 300;
          font-size: 18px;

          border-bottom: 4px solid white;

          &:first-child {
            margin-right: 30px;
          }
          &:not(:first-child):not(:last-child) {
            margin-left: 30px;
            margin-right: 30px;
          }
          &:last-child {
            margin-left: 30px;
          }

          button {
            background: none;
            border: none;
            padding: 0;
          }

        }

        .dashboard-widget-tab-active {
          font-weight: bold;
          border-bottom: 4px solid #4C9E45;
        }

      }

    }

    .dashboard-widget-bodies {

      height: 100%;

      .dashboard-widget-body {

        display: none;
        height: 260px;
        overflow-y: auto;

        .loader-container {
          margin-top: 3rem;
        }

        h3 {
          font-size: 18px;
          margin-top: 18px;
          margin-bottom: 18px;
          color: #333333;
        }

        .dashboard-widget-due-date {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          margin-top: 1rem;
          margin-bottom: 1rem;
          color: #0B3C61;
          white-space: nowrap;
          i {
            font-size: 24px;
            margin-right: 1rem;
          }
          a {
            font-weight: bold;
            width: 45%;
          }

          .due-today-wrapper {
            position: relative;
            .due-today-icon {
              position: absolute;
              width: 16px;
              height: 16px;
              background: url("../images/duetoday.png") center center;
              background-size: contain;
              top: -6px;
              right: 0;
              z-index: 2;
            }
          }
          span {
            padding-left: 25px;
          }
        }
      }

      .dashboard-widget-body-active {
        display: block !important;
      }

    }

    .comment-count-wrapper {
      position: relative;
      margin-right: 10px;
      min-width: 34px;
      .badge {
        background-color: #B00505;
        color: #fff;
        position: absolute;
        top: -5px;
        right: 0px;
      }
    }

    .activity-export-icon {
      margin-right: 20px;
    }
  }

  .bar-graph {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    font-size: 12px;

    td {
      text-align: center;
    }

    .bar-wrapper {
      position: relative;
      vertical-align: bottom;
      padding-bottom: 3px;
    }

    .bar {
      width: 20px;
      background-color: #4C9E45;
      border-radius: 13px;
      margin: auto;
    }

    .labels {
      td {
        border-top: 0.5px dashed #BDBDBD;
        height: 65px;
        padding-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      td:hover {
        overflow: visible;
        height: 65px;
        word-break: break-all;
      }
    }
  }

}

.super-admin-banner {
  padding: 8px;

  ul {
    margin: 0;
  }
}

/* Smart Exchange CSS starts here */
#loader, #sb-loader {
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid #444444;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;

  &.sb-visible {
    display: block;
    position: fixed;
    top: 30%;
    left: 0;
    right: 0;
    margin: 5% auto;
    z-index: 9999;
  }
}

#sb-loader {
  display: none;
}

#sb-loader-hide {
  opacity: 0;
  position: fixed;
  top: -99999px;
  left: -99999px;
  z-index: 9999;
  text-transform: uppercase;
  transform: translate(-50%, 0%);
  transition: opacity 10s linear;

  &.sb-visible-hide {
    display: block;
    opacity: 1;
    top: 28%;
    left: 50%;
  }

  #closeLoader {
    color: #fff;
    font-weight: bold;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}


.sx-doc-box {
  border: 1px solid #D3D6D9;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px 0;
  padding: 15px;
}

.sx-green-circle {
  color: green;
  font-size: 20px;
}

.sx-red-circle {
  color: red;
  font-size: 20px;
}

.sx-blue-circle {
  color: #0A3C61;
  font-size: 20px;
}

.sx-yellow-circle {
  color: #F2994A;
  font-size: 20px;
}

.sx-doc-name {
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */
  color: #0B3C61;
}

.sx-doc-header {
  font-size: 14px;
  line-height: 28px;
  /* identical to box height */
  color: #0B3C61;
}
.sx-upload-table .sx-doc-header{
  line-height: 34px;
}
.sx-doc-desc {
  font-size: 16px;
  line-height: 19px;
  /* Text / Text color */
  color: rgba(0, 0, 0, 0.7);
}

.sx-pl-20 {
  padding-left: 20px;
}

.sx-pb-15 {
  padding: 0 0 15px;
}

.sx-pt-15 {
  padding: 15px 0 0;
}
.sx-ptb-15{
  padding:15px 0;
}
.sx-border-underline {
  border-bottom: 1px dashed #BDBDBD;
}

.sx-doc-temp {
  font-size: 14px;
  line-height: 16px;
  /* Registration Blue */
  color: #2F80ED;
}

.sx-ul-button-blue {
  background: #FFFFFF;
  /* Emergo Dark Blue */
  color: #0B3C61;
  border: 1px solid #0B3C61;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 500;
  outline: none;
}

.sx-ul-button-blue:hover,
.sx-ul-button-blue:hover,
.sx-ul-button-blue:active,
.sx-ul-button-blue:focus,
.sx-ul-button-blue.active.focus,
.sx-ul-button-blue.active:focus,
.sx-ul-button-blue.active:hover,
.sx-ul-button-blue:active.focus,
.sx-ul-button-blue:active:focus,
.sx-ul-button-blue:active:hover {
  background: #FFFFFF;
  /* Emergo Dark Blue */
  color: #0B3C61;
  border: 1px solid #0B3C61;
  outline: none;
}

.sx-ft-16 {
  font-size: 16px;
}

.sx-doc-desc p {
  margin-bottom: 15px;
}

.sx-comment-link {
  font-size: 16px;
  line-height: 19px;

  /* Blue 1 */

  color: #2F80ED;
}

.sx-ul-button-green {
  background: #4C9E45;
  /* Em ergo colors / Green */
  border: 1px solid #4C9E45;
  box-sizing: border-box;
  border-radius: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  outline: none;
}

.sx-ul-button-green:hover,
.sx-ul-button-green:hover,
.sx-ul-button-green:active,
.sx-ul-button-green:focus,
.sx-ul-button-green.active.focus,
.sx-ul-button-green.active:focus,
.sx-ul-button-green.active:hover,
.sx-ul-button-green:active.focus,
.sx-ul-button-green:active:focus,
.sx-ul-button-green:active:hover {
  background-color: #4c9e45;
  border-color: #4c9e45;
  color: #fff;
  box-shadow: none;
  outline: none;
}

.sx-ul-button-exgreen {
  background: #4C9E45;
  /* Em ergo colors / Green */
  border: 1px solid #4C9E45;
  box-sizing: border-box;
  border-radius: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  width: 200px;
  outline: none;
}

.sx-ul-button-exgreen:hover,
.sx-ul-button-exgreen:hover,
.sx-ul-button-exgreen:active,
.sx-ul-button-exgreen:focus,
.sx-ul-button-exgreen.active.focus,
.sx-ul-button-exgreen.active:focus,
.sx-ul-button-exgreen.active:hover,
.sx-ul-button-exgreen:active.focus,
.sx-ul-button-exgreen:active:focus,
.sx-ul-button-exgreen:active:hover {
  background-color: #4c9e45;
  border-color: #4c9e45;
  color: #fff;
  box-shadow: none;
  outline: none;
}


.sx-ul-button-exblue {
  background: #0b3c61;
  /* Em ergo colors / Green */
  border: 1px solid #0b3c61;
  box-sizing: border-box;
  border-radius: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  width: 200px;
  outline: none;
}

.sx-ul-button-exblue:hover,
.sx-ul-button-exblue:hover,
.sx-ul-button-exblue:active,
.sx-ul-button-exblue:focus,
.sx-ul-button-exblue.active.focus,
.sx-ul-button-exblue.active:focus,
.sx-ul-button-exblue.active:hover,
.sx-ul-button-exblue:active.focus,
.sx-ul-button-exblue:active:focus,
.sx-ul-button-exblue:active:hover {
  background-color: #0b3c61;
  border-color: #0b3c61;
  color: #fff;
  box-shadow: none;
  outline: none;
}

.p-0{
  padding:0;
}

.sx-footer-buttons {
  display: inline-flex;
  justify-content: flex-end;
  padding: 0px;
  align-items: center;
}

.sx-btn-size-120 {
  width: 120px;
}

.sx-btn-size-100 {
  width: 100px;
  margin-right: 30%;
}

.sx-btn-size-180 {
  width: 180px;
  margin-right: 18%;
}

.sx-btn-size-135 {
  width: 135px;
}

.sx-btn-size-140 {
  width: 140px;
}

.sx-due-date {
  margin-right: 10px;
  font-size: 16px;
}

.sx-mr-lr-15 {
  margin: 0 15px;
}


.sx-checkbox label:after {
  content: "";
  display: table;
  clear: both;
}

.sx-checkbox .sx-cr {
  position: relative;
  display: inline-block;
  border: 1px solid #0b3c61;
  border-radius: 1px;
  width: 15px;
  height: 15px;
}

.sx-checkbox .sx-cr .sx-cr-icon {
  position: absolute;
  font-size: 10px;
  line-height: 0;
  top: 45%;
  left: 8%;
}

.sx-checkbox label input[type="checkbox"] {
  display: none;
}

.sx-checkbox label input[type="checkbox"]+.sx-cr>.sx-cr-icon {
  opacity: 0;
}

.sx-checkbox label input[type="checkbox"]:checked+.sx-cr>.sx-cr-icon {
  opacity: 1;
  color: #0b3c61;
}

.sx-checkbox label input[type="checkbox"]:disabled+.sx-cr {
  opacity: 0.5;
}

.sx-checkbox label {
  padding-left: 0;
  padding-top: 5px;
}

.sx-checkbox {
  display: inline;
  vertical-align: middle;
}

.sx-checkbox-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  color: #0B3C61;
}

.filelimitmsg {
  background: url(../images/Groupmask.jpg);
  background-repeat: no-repeat;
  background-size: auto;
  padding: 26px;
  width: 1137px;
  height: 263px;
  margin: 0 17px 31px 18px;
}

.imgbannerstyle {
  /* BodyText_reg16 */
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #fff;

}


.modal-header .close {
  background: none;
  opacity: 0.5;
  height: 17px;
  line-height: 17px;
  color: #828282;
  width: 17px;
  border-radius: 20px;
  font-weight: 300;
  outline: none;
  font-size: 32px;
}
.modal-header.deactiveModal-bgcolor {
  background-color: #ffffff;
  border: 0px;
  border-radius: 10px;
}
.sx-modal-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #4c9e45;
}

.modal-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
}
.img-size {
  width: 97px;
  height: 97px;
}
.modal-body {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}

.sx-modal-body {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.modal-footer.deactiveModal-bgcolor {
  background-color: #ffffff;
  border: 0px;
  border-radius: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.pd-45{
  padding:0px 45px;
}
.smart-button {
  background: #0b3c61;
  border: 1px solid #0b3c61;
  box-sizing: border-box;
  border-radius: 20px;
  color: #ffffff;
}

.smart-button:hover {
  color: #fff;
  transform: scale(1.1)
}

.footer-prop {
  left: 40px;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .modal-dialog{
    width: 305px;
    height: 458px;
  }
  .footer-prop {
    left: 0px;
    margin-top: 0px;
  }
}

.export-progress-cell {
  padding: 0 !important;
}

.export-progress {
  margin-bottom: 0;
  height: 4px;
}

.text-sm {
  font-size: 12px;
}

.sxswitch {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 34px;
}

.sxslider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2196F3;
  -webkit-transition: .4s;
  transition: .4s;
}

.sxslider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.sxinput:checked + .sxslider {
  background-color: #2196F3;
}

.sxinput:focus + .sxslider {
  box-shadow: 0 0 1px #2196F3;
}

.sxinput:checked + .sxslider:before {
  -webkit-transform: translateX(85px);
  -ms-transform: translateX(85px);
  transform: translateX(85px);
}

.sxtext,.sxswitchtext {
  color: white;
}

.sxtext:after {
  position: absolute;
  top: 7px;
  right: 12px;
  content: "Client";
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.sxtext:before {
  position: absolute;
  top: 7px;
  left: 10px;
  content: "Consultant";
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}


.sxswitchtext:after {
  position: absolute;
  top: 7px;
  right: 12px;
  content: "Assigned";
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.sxswitchtext:before {
  position: absolute;
  top: 7px;
  left: 10px;
  content: "All";
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}



.sxinput + .sxslider + .sxtext:after, .sxinput + .sxslider + .sxswitchtext:after{
  opacity: 1;
}

.sxinput + .sxslider + .sxtext:before,.sxinput + .sxslider + .sxswitchtext:before {
  opacity: 0;
}

.sxinput:checked + .sxslider + .sxtext:after, .sxinput:checked + .sxslider + .sxswitchtext:after{
  opacity: 0;
}

.sxinput:checked + .sxslider + .sxtext:before,.sxinput:checked + .sxslider + .sxswitchtext:before {
  opacity: 1;
}

/* Rounded sliders */
.sxslider.sxround {
  border-radius: 34px;
}

.sxslider.sxround:before {
  border-radius: 50%;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 10px;
}

.link-container {
  max-height: 30vh;
  overflow: auto;
  .list-group-item {
    padding: 5px 10px;
  }
}

.prod-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  .alert {
    background-color: #ff2525;
    padding: 8px;
    text-align: center;
    color: #fff;
  }
}

.widget-container {
  .form-control-min-height {
    min-height: 200px !important;
    overflow-y: auto;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 200px;
  }
}

.add-comment-container {
  .form-control:not(.target) {
    min-height: 100px !important;
    overflow-y: auto;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 100px;
  }
}

.loading-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
  background-color: rgba(0, 0, 0, .5);
}

.keywords-used-in {
  max-height: 40vh;
  overflow-y: auto;

  .keyword-row {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
  }
}



/***** toggle switch ul design ******/
.sxswitchnew {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
  margin-left: 25px;
  margin-bottom: 0px;
  vertical-align: middle;
}
.sxswitchnew input {
  opacity: 0;
  width: 0;
  height: 0;
}
.sxslidernew {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sxslidernew:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  bottom: -1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
}
input:checked + .sxslidernew {
  background-color: #4c9e45;
}
input:focus + .sxslidernew {
  -webkit-box-shadow: 0 0 1px #4c9e45;
  box-shadow: 0 0 1px #4c9e45;
}
input:checked + .sxslidernew:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.sxslidernew.sxroundnew {
  border-radius: 34px;
}
.sxslidernew.sxroundnew:before {
  border-radius: 50%;
}
.sxtoggle_switchnew .sxswitch_labelnew,.sxtoggle_switchnew .sxswitch_label,.sxtoggle_switchnew .sxswitch_label_cType,.switch_cons_label {
  margin-left: 10px;
  color: #00518a;
  vertical-align: top;
}

.showalert {
  line-height: 1.5;
  margin: 38px 40px -26px 23px;
  text-align: justify;
}
.keywords-used-in {
  max-height: 40vh;
  overflow-y: auto;

  .keyword-row {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
  }
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.pt-1 {
  padding-top: 5px;
}

.w-50 {
  width: 50%;
}

.csv-help {
  margin-top: 5px;
  font-size: 12px;
}

/***** datepicker styles ******/
.uib-datepicker-popup.dropdown-menu .text-info {
  color : #31708f;
}

/**** Datatable styles ***/
.pagination-right,
.pagination-left {
  color: #00518a;
}
.disable.pagination-right,
.disable.pagination-left {
  color: #00518a;
}

.activity-tracker-table {

  font-family: Roboto;

  .table {
    thead {
      background-color: white;
    }

    > tbody {
      > tr {
        > td {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.7);
          padding: 10px 10px 10px 3px;
          border-top: none;
        }

        &:hover {
          background: #cbe4f6;
        }

        &:nth-child(even) {
          > td {
            border-top: 1px solid #bdbdbd;
            border-bottom: 1px solid #bdbdbd;
          }
        }
      }
    }

    > thead {
      > tr {
        > th {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          color: #00518a;
          border-bottom: 1px dashed #bdbdbd;
          vertical-align: middle;
          padding: 10px 20px 10px 3px;
          text-align: left;
        }
      }
    }
  }

  table.dataTable.no-footer {
    border: none;
  }


  table.dataTable {
    thead {

      .sorting {

        background-image: url(../images/sort_asc.png);

        &.sorting_asc {

          background-image: url(../images/sort_asc.png);

        }

        &.sorting_desc {

          background-image: url(../images/sort_desc.png);

        }

      }

    }
  }



  .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    padding-top: 20px;
    align-items: center;
    border-top: 1px solid #bdbdbd;

    .dataTables_paginate {
      order: 2;
      padding-top: 0;

      .paginate_button {
        color: #BDBDBD !important;
        padding: 5px 10px;

        &:hover, &:focus {
          border: none !important;
          color: @blue !important;
          background: none !important;
        }

        &.current {
          border: none;
          border-bottom: 4px solid green;
          color: @blue !important;
          background: none;
          padding: 0;
        }
      }
    }

    .dataTables_length {
      order: 1;
      color: rgba(0, 0, 0, 0.7);
      select {
        &:focus-visible{
          border-radius: 20px;
          border: 1px solid #0b3c61;
        }
        &:focus{
          outline: none;
        }
        width: 100px;
        border-radius: 20px;
        border: 1px solid #0b3c61;
        margin: 0 5px;
      }
    }

    .dataTables_info {
      order: 3;
    }
  }

  .dataTables_filter {
    input {
      border-radius: 20px;
    }
  }

}
.sx_title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #00518a;
}
.search-container {
  .vertical-line {
    top: 5px;
    right: 58px;
    position: absolute;
    border-left: 1px solid #828282;
    height: 75%;
  }
  .search-reset {
    top: 7px;
    right: 50px;
    height: 22px;
    width: 22px;
  }
  .search-box {
    padding-left: 16px;
    color: #000000;
    font-weight: normal;
    padding-right: 84px;
    height: 36px;
    border-radius: 20px;
    padding-left: 16px;
    color: #000000;
    font-weight: normal;
    padding-right: 84px;
  }
  .search-icon {
    top: 8px;
    position: absolute;
    right: 28px;
  }
  .events-none {
    cursor: not-allowed;
    pointer-events: none;
  }
}
.switch_label,.switch_labelnew,.sxswitch_label,.switch_cons_label {
  margin-left: 10px;
  color: #00518a;
  vertical-align: top;
}
.toggle_switch {
  margin-top: 10px;
  padding: 0;
  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 20px;
    margin-left: 10%;
    margin-bottom: 0px;
    vertical-align: middle;
    text-align: left;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 23px;
      width: 23px;
      bottom: -1px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    }
  }
  input {
    &:checked+.slider {
      background-color: #4c9e45;
      &:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }
    &:focus+.slider {
      box-shadow: 0 0 1px #4c9e45;
    }
  }
  .slider.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
}
.text-align-right {
  text-align: right;
}
.mb-20 {
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .toggle_switch {
    padding-right: 40px;
  }
}
.projectName{
  cursor: pointer;
}

.sb-btn-size-80 {
  width: 80px;
  font-size: 15px;
}
.preview-links-col-size {
  width : 85px;
}
.dashboard-no-data {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto;
  width: 1067px;
  height: 38px;
  line-height: 18.75px;
  margin: 10px;
}

.sxlinks:hover{
  font-weight: 900;
}

.sxnotification {
  position: relative;
}

.sxbadge {
  position: absolute;
  top: 0px;
  right: 20px;
  color: red;
  font-size: smaller;
}

.sd-search-container {
  /*position: relative;
  width: 466px;
  margin-left : 423px;*/
  margin-bottom : 21px;
}

.sd-search-input {
  width: 100%;
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  outline: none;
}

.sd-clear-button {
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%);
  cursor: pointer;
}

