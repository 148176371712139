.literature-review {

  #body.finalized {
    opacity: .65;
    button, select, input {
      display: none;
      &.finalize-visible {
        display: inherit;
      }
    }
  }

  .list-group-item.list-group-title-abstract {
    a {
      color: #ccc !important;
    }
  }

 .list-group.list-search-sidebar {
    margin-bottom: 0;
  }

  .list-group-item.header {
    background-color: #00518a;
    min-height: 40px;
    a {
      color: #fff;
    }

    .badge {
      background-color: #fff;
      color: #00518a;
    }
  }

  .list-group-item-active {
    background-color: #efefef;
  }

  .search-terms {
    font-size: 11px;
    margin-top: 2px;
  }

  .search-header {
    font-size: 18px;
    font-weight: bold;
  }

  .duplicates-tab a {
    color: #ccc;
  }

  .sidebar-actions {
    margin-top: 20px;
  }

  .loading-wrapper {
    text-align: center;
    min-height: 32px;
  }

  .mb-1 {
    margin-bottom: 5px;
  }

  .litreview-search-header {
    position: relative;
    .comment-button-container {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .dropdown-menu {
    .rs-dropdown;
    > li > a {
      font-weight: 400;
      font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    &> li {
      padding-bottom: 3px;
      border-bottom: 1px solid fade(@borderPrimary, 50%);
    }
  }

  .appraisal-container {
    .appraisal-header {
      margin-bottom: 10px;
    }

    .appraisal-reference {
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ddd;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-control-min-height {
    min-height: 100px !important;
    overflow-y: auto;
  }

  .with-min-width {
    min-width: 50vw;
  }

  .appraisal-section {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid #eee;
  }
}