.comments-container {

  position: absolute;
  right: -5px;
  top: 35px;
  width: 500px;
  max-height: 50vh;
  overflow-y: auto;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.25);
  padding: 15px;
  background-color: #fff;
  font-weight: 300;

  &.literature-review-comments {
    position: relative;
    width: auto;
    right: 0;
    top: 0;
  }

  &.literature-review-comment {
    max-height: 100vh;
  }

  .comments-action, .show-hide-comments {
    font-size: 18px;
  }

  .show-hide-comments {
    margin-right: 5px;
  }

  .btn {
    border-radius: 20px !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 21px !important;
    text-align: center;
  }

  p {
    margin: 0;
  }

  .reply-comment-wrapper {
    margin-top: 15px;
    text-align: right;
  }

  .comments-header {
    margin-bottom: 10px;
  }

  .comment-panel-buttons {
    font-size: 11px;
    float: left;
    a {
      text-decoration: underline;
    }
  }

  .comment-body-replies {
    margin-left: 10px;
    padding-left: 10px;
    margin-top: 10px;

    .comment-reply-delete {
      margin-bottom: 2rem;
      text-align: right;
    }

    .add-reply-form {
      margin-top: 5px;
      .medium-buttons {
        display: none
      }
    }
  }

  .panel-add-comment {
    margin-top: 10px;

    .form-group:last-child {
      margin-bottom: 0;
    }

    .add-comment-container, .ck-editor__editable_inline {
      min-height: 100px;
    }

    .medium-buttons {
      display: none;
    }
  }

  .comment-buttons {
    margin-top: 5px;
    text-align: right;
    ul {
      margin-bottom: 0;
    }
  }

  .comment-component-container {
    padding: 10px;
    border: 1px solid #D3D6D9;
    border-radius: 8px;
    margin-bottom: 5px;
    border-left: 7px solid #000;

    &.comment-external {
      border-left-color: #4C9E45;
    }

    &.comment-internal {
      border-left-color: #00518A;
    }
  }

  .comment-component-container-header {
    margin-bottom: 10px;
    table {
      width: 100%;
    }
  }

  .comment-body-user {
    font-weight: 400;
  }

  .comment-body-text {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .no-permission {
    color: #bbb;
  }
}

.sx-comment-container {
  position: relative;
  .comments-container {
    position: relative;
    right: 0px;
    width: 100%;
  }
}