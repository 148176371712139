.body-container {
  margin-top: 55px;
  margin-bottom: 60px;
}

.body-no-header {
  .body-container {
    margin-top: 0;
  }
  
  .page-header {
    margin-top: 0;
  }

  .home-link {
    float: right;

    .dropdown-toggle {
      width: max-content;
    }
  }
}

.main-nav {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f8f8f8;
  border-bottom: 1px solid fade(@borderPrimary, 50%);
  border-top: 1px solid fade(@borderPrimary, 50%);
  position: fixed;
  display: table;
  width: 100%;
  z-index: 1000;
  top: 0;
  
  .logo-container img {  
    height: 30px;  
  }
  
  .logo-left {
    display: table-cell;
    vertical-align: middle;
  }
  
  .menu-right {
    display: table-cell;
    width: 100%;
    text-align: right;
    vertical-align: middle;
    
    .dropdown-toggle {
      font-size: 1em;
      color: @blue;
      border-radius: 2px;
    }
    
    .dropdown {
      float: right;
    }
    
    .dropdown-menu {
      .rs-dropdown;  
    }
  }
}

.sidebar {
  li a {
    border-radius: 0;
  }
}