.body-tools {
  .tools-row-content {
    margin-top: 30px;
  }

  .tools-upload-btn-container {
    text-align: center;
  }

  .token-with-token {
    position: absolute;

  }

  .tools-shrink-percent {
    font-size: 1.3em;
    font-weight: bold;
    margin-top: 10px;
  }
}